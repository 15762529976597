import React from "react"
import styled from "styled-components"
import Hero from "../components/landing-pages/Lp1/hero"
import BulletPoints from "../components/landing-pages/Lp1/bulletPoints"
import Showcase from "../components/landing-pages/Lp1/showcase"
import Cta from "../components/landing-pages/Lp1/cta"
import Seo from "../components/seo"
import HeaderLp from "../components/landing-pages/headerLp"
import StickyCtaClosable from "../components/landing-pages/stickyCtaClosable"
import { useContext, useEffect } from "react"
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import saleDate from "../utils/saleDate"
const Main = styled.div``

const Lp1 = ({ location }) => {
  const { closedSticky, setClosedSticky } = useContext(GlobalAuthContext)
  useEffect(() => {
    if (closedSticky == null) {
      setClosedSticky(false)
    }
  }, [])

  return (
    <Main>
      {!closedSticky && (
        <StickyCtaClosable
          date={`Get 38% off for a limited time! Sale ends ${saleDate()}.`}
        ></StickyCtaClosable>
      )}
      <HeaderLp
        wrapLocation={location}
        location={`uklp`}
        closedSticky={closedSticky}
      />
      <Seo title="Create &amp; Edit PDF files with PDF Pro" noindex={true} />
      <Hero location={location}></Hero>
      <BulletPoints location={location}></BulletPoints>
      <Showcase></Showcase>
      <Cta location={location}></Cta>
    </Main>
  )
}

export default Lp1
